<template>
    <div class="grid p-fluid">
        <div class="col-12">
            <h2>Create New Tour</h2>
            <div class="card">
                <Steps :model="items" :readonly="true"></Steps>
            </div>

            <router-view v-slot="{Component}" :formData="formObject" @prevPage="prevPage($event)" @nextPage="nextPage($event)" >
                <keep-alive>
                    <component :is="Component" />
                </keep-alive>
            </router-view>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
    setup() {
        const router = useRouter();

        const items = ref([
            {
                label: 'Basics',
                to: "/tours/create"
            },
            {
                label: 'Schedule',
                to: "/tours/create/schedule",
            },
            {
                label: 'Policy',
                to: "/tours/create/policies",
            },
            {
                label: 'Picture',
                to: "/tours/create/pictures",
            },
            {
                label: 'Overview',
                to: "/tours/create/overview",
            }
        ]);
        const formObject = ref({});

        const nextPage = (event) => {
            for (let field in event.formData) {
                formObject.value[field] = event.formData[field];
            }
            router.push(items.value[event.pageIndex + 1].to);
        };
        const prevPage = (event) => {
            router.push(items.value[event.pageIndex - 1].to);
        };

        return { items, formObject, nextPage, prevPage }
    }
}
</script>

<style>
.p-steps .p-steps-item .p-menuitem-link {
    flex-direction: column;
    /* flex-wrap: wrap; */
    flex: 1 1 auto;
    padding: 0.5rem 0rem;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #007bff;
    color: #ffffff;
    border-radius: 4px;
}
.p-steps .p-steps-item .p-steps-number {
    border-radius: 4px !important;
    float: left !important;
}
.p-steps .p-steps-item .p-steps-title {
    float: left !important;
    margin-top: 3px !important;
}
</style>